export default {
  "Customer": {
    "nl": "Klant",
    "fr": "Client"
  },
  "Logout": {
    "nl": "Uitloggen",
    "fr": "Déconnexion"
  },
  "No results found": {
    "nl": "Geen resultaten gevonden",
    "fr": "Aucun résultat trouvé"
  },
  "Collection Orders": {
    "nl": "Inzamelopdrachten",
    "fr": "Commandes de collecte"
  },
  "Invoices": {
    "nl": "Facturen",
    "fr": "Factures"
  },
  "Orders": {
    "nl": "Orders",
    "fr": "Commandes"
  },
  "Order number": {
    "nl": "Ordernummer",
    "fr": "Numéro de commande"
  },
  "Reports": {
    "nl": "Rapporten",
    "fr": "Rapports"
  },
  "Statistics": {
    "nl": "Statistieken",
    "fr": "Statistiques"
  },
  "Last Orders": {
    "nl": "Laatste orders",
    "fr": "Dernières commandes"
  },
  "Last Invoices": {
    "nl": "Laatste facturen",
    "fr": "Dernières factures"
  },
  "Date": {
    "nl": "Datum",
    "fr": "Date"
  },
  "Invoice": {
    "nl": "Factuur",
    "fr": "Facture"
  },
  "Status": {
    "nl": "Status",
    "fr": "Statut"
  },
  "No results": {
    "nl": "Geen resultaten",
    "fr": "Aucun résultat"
  },
  "All invoices": {
    "nl": "Alle facturen",
    "fr": "Toutes les factures"
  },
  "Order": {
    "nl": "Volgorde",
    "fr": "Commande"
  },
  "All orders": {
    "nl": "Alle orders",
    "fr": "Toutes les commandes"
  },
  "Your account manager": {
    "nl": "Jouw accountmanager",
    "fr": "Votre gestionnaire de compte"
  },
  "My name is": {
    "nl": "Mijn naam is",
    "fr": "Je m'appelle"
  },
  "and i am your account manager. If anything don`t hesitate to contact me": {
    "nl": "en ik ben jouw accountmanager. Als er iets is, aarzel dan niet om contact met mij op te nemen.",
    "fr": "et je suis votre gestionnaire de compte. Si vous avez des questions, n'hésitez pas à me contacter."
  },
  "No account manager": {
    "nl": "Geen accountmanager",
    "fr": "Pas de gestionnaire de compte"
  },
  "Call": {
    "nl": "Telefoongesprek",
    "fr": "Appel"
  },
  "Call me back": {
    "nl": "Bel me terug",
    "fr": "Rappelle-moi"
  },
  "Plan new order": {
    "nl": "Plan nieuwe order",
    "fr": "Planifier une nouvelle commande"
  },
  "Plan": {
    "nl": "Plan",
    "fr": "Planifier"
  },
  "Select disposal address": {
    "nl": "Selecteer ontdoeningsadres",
    "fr": "Sélectionner l'adresse de disposition"
  },
  "Origin": {
    "nl": "Oorsprong",
    "fr": "Origine"
  },
  "Waste Group": {
    "nl": "Afvalgroep",
    "fr": "Groupe de déchets"
  },
  "Select a waste group": {
    "nl": "Selecteer een afvalgroep",
    "fr": "Sélectionnez un groupe de déchets"
  },
  "Add new order": {
    "nl": "Nieuwe order toevoegen",
    "fr": "Ajouter une nouvelle commande"
  },
  "Eural Code": {
    "nl": "Euralcode",
    "fr": "Code Eural"
  },
  "Select eural code": {
    "nl": "Selecteer euralcode",
    "fr": "Sélectionnez le code Eural"
  },
  "Estimated weight": {
    "nl": "Geschat gewicht",
    "fr": "Poids estimé"
  },
  "is required": {
    "nl": "vereist",
    "fr": "est requis"
  },
  "Customer reference": {
    "nl": "Klantreferentie",
    "fr": "Référence client"
  },
  "Note to driver": {
    "nl": "Opmerking voor chauffeur",
    "fr": "Note au conducteur"
  },
  "Call upfront": {
    "nl": "Vooraf bellen",
    "fr": "Appeler à l'avance"
  },
  "Urgent (extra costs can be applied)": {
    "nl": "Spoed (extra kosten kunnen in rekening gebracht worden)",
    "fr": "Urgent (des coûts supplémentaires peuvent être appliqués)"
  },
  "History": {
    "nl": "Geschiedenis",
    "fr": "Historique"
  },
  "Close": {
    "nl": "Sluiten",
    "fr": "Fermer"
  },
  "Global Search": {
    "nl": "Globaal zoeken",
    "fr": "Recherche globale"
  },
  "Search": {
    "nl": "Zoeken",
    "fr": "Rechercher"
  },
  "Collected": {
    "nl": "Ingezameld",
    "fr": "Collecté"
  },
  "Disposer": {
    "nl": "Ontdoener",
    "fr": "Disposant"
  },
  "Weight": {
    "nl": "Gewicht",
    "fr": "Poids"
  },
  "No orders found.": {
    "nl": "Geen orders gevonden.",
    "fr": "Aucune commande trouvée."
  },
  "Showing {first} to {last} of {totalRecords}": {
    "nl": "Toon {first} tot {last} van {totalRecords}",
    "fr": "Affichage de {first} à {last} sur {totalRecords}"
  },
  "Invoice date": {
    "nl": "Factuurdatum",
    "fr": "Date de la facture"
  },
  "Due date": {
    "nl": "Betaaldatum",
    "fr": "Date d'échéance"
  },
  "Type": {
    "nl": "Type",
    "fr": "Type"
  },
  "No invoices found.": {
    "nl": "Geen facturen gevonden.",
    "fr": "Aucune facture trouvée."
  },
  "Generate report(s)": {
    "nl": "Genereer rapport(en)",
    "fr": "Générer des rapport(s)"
  },
  "Select eural codes": {
    "nl": "Selecteer euralcodes",
    "fr": "Sélectionnez des codes Eural"
  },
  "Choosen eural codes": {
    "nl": "Gekozen euralcodes",
    "fr": "Codes Eural choisis"
  },
  "Select date range": {
    "nl": "Selecteer datumbereik",
    "fr": "Sélectionnez la plage de dates"
  },
  "Select report format": {
    "nl": "Selecteer rapportformaat",
    "fr": "Sélectionnez le format du rapport"
  },
  "1 page per eural code": {
    "nl": "1 pagina per euralcode",
    "fr": "1 page par code Eural"
  },
  "Grouped": {
    "nl": "Gegroepeerd",
    "fr": "Groupé"
  },
  "Other email address": {
    "nl": "Ander e-mailadres",
    "fr": "Autre adresse e-mail"
  },
  "Email": {
    "nl": "Email",
    "fr": "Email"
  },
  "Download": {
    "nl": "Download",
    "fr": "Télécharger"
  },
  "Please fill in the other email address": {
    "nl": "Vul een ander e-mailadres in",
    "fr": "Veuillez remplir l'autre adresse e-mail"
  },
  "Send per e-mail": {
    "nl": "Verstuur per e-mail",
    "fr": "Envoyer par e-mail"
  },
  "Select waste group": {
    "nl": "Selecteer een afvalgroep",
    "fr": "Sélectionnez un groupe de déchets"
  },
  "Submit": {
    "nl": "Opslaan",
    "fr": "Soumettre"
  },
  "Total collected": {
    "nl": "Totaal ingezameld",
    "fr": "Total collecté"
  },
  "Difference": {
    "nl": "Verschil",
    "fr": "Différence"
  },
  "No data": {
    "nl": "Geen gegevens",
    "fr": "Aucune donnée"
  },
  "Amount of orders": {
    "nl": "Bedrag orders",
    "fr": "Montant des commandes"
  },
  "Amount": {
    "nl": "Bedrag",
    "fr": "Montant"
  },
  "Average weight per order": {
    "nl": "Gemiddeld gewicht per order",
    "fr": "Poids moyen par commande"
  },
  "Co2 savings (used oil)": {
    "nl": "Co2-besparing (afgewerkte olie)",
    "fr": "Économies de CO2 (huile usagée)"
  },
  "Not applicable for this waste group": {
    "nl": "Niet van toepassing voor deze afvalgroep",
    "fr": "Non applicable pour ce groupe de déchets"
  },
  "January": {
    "de": "Januar",
    "nl": "Januari",
    "fr": "Janvier"
  },
  "February": {
    "de": "Februar",
    "nl": "Februari",
    "fr": "Février"
  },
  "March": {
    "de": "März",
    "nl": "Maart",
    "fr": "Mars"
  },
  "April": {
    "de": "April",
    "nl": "April",
    "fr": "Avril"
  },
  "May": {
    "de": "Kann",
    "nl": "Kunnen",
    "fr": "Mai"
  },
  "June": {
    "de": "Juni",
    "nl": "Juni",
    "fr": "Juin"
  },
  "July": {
    "de": "Juli",
    "nl": "Juli",
    "fr": "Juillet"
  },
  "August": {
    "de": "August",
    "nl": "Augustus",
    "fr": "Août"
  },
  "September": {
    "de": "September",
    "nl": "September",
    "fr": "Septembre"
  },
  "October": {
    "de": "Oktober",
    "nl": "Oktober",
    "fr": "Octobre"
  },
  "November": {
    "de": "November",
    "nl": "november",
    "fr": "Novembre"
  },
  "December": {
    "de": "Dezember",
    "nl": "December",
    "fr": "Décembre"
  },
  "Welcome to our customer portal": {
    "nl": "Welkom in ons nieuwe klantportaal",
    "fr": "Bienvenue sur notre portail client"
  },
  "Please sign in below to access your data.": {
    "nl": "Log hieronder in om bij uw gegevens te komen.",
    "fr": "Veuillez vous connecter ci-dessous pour accéder à vos données."
  },
  "Password": {
    "nl": "Wachtwoord",
    "fr": "Mot de passe"
  },
  "Forgot Password?": {
    "nl": "Wachtwoord vergeten?",
    "fr": "Mot de passe oublié ?"
  },
  "Sign in": {
    "nl": "Log in",
    "fr": "Se connecter"
  },
  "Create an account": {
    "nl": "Maak een account aan",
    "fr": "Créer un compte"
  },
  "New on our portal?": {
    "nl": "Nieuw binnen het klantportaal?",
    "fr": "Nouveau sur notre portail ?"
  },
  "Sing up to our customer portal": {
    "nl": "Meld je aan je het klantportaal",
    "fr": "Inscrivez-vous sur notre portail client"
  },
  "Please fill in your e-mail address.": {
    "nl": "Vul je e-mailadres in.",
    "fr": "Veuillez saisir votre adresse e-mail."
  },
  "Sign up": {
    "nl": "Aanmelden",
    "fr": "S'inscrire"
  },
  "Password reset succesfull if": {
    "nl": "Wachtwoord reset is succesvol als",
    "fr": "Réinitialisation du mot de passe réussie si"
  },
  "Password reset succesfull": {
    "nl": "Wachtwoord reset is succesvol",
    "fr": "Réinitialisation du mot de passe réussie"
  },
  "your e-mail address is known in our system, you will receive an e-mail address with a link to change your password": {
    "nl": "je e-mailadres bekend is in ons systeem; je ontvangt een e-mail met daarin een link om je wachtwoord te resetten",
    "fr": "votre adresse e-mail est connue dans notre système, vous recevrez un e-mail avec un lien pour changer votre mot de passe"
  },
  "Back to login": {
    "nl": "Terug naar loginscherm",
    "fr": "Retour à la connexion"
  },
  "Direct phone number": {
    "nl": "Telefoonnummer",
    "fr": "Numéro de téléphone direct"
  },
  "Sign up succesfull if": {
    "nl": "Aanmelden is succesvol als",
    "fr": "Inscription réussie si"
  },
  "your e-mail address is known in our system and you are granted access to our customer portal, you should recieve an e-mail with further instructions.": {
    "nl": "je e-mailadres bij ons bekend is in het systeem en je toegang hebt gekregen tot het portaal.",
    "fr": "votre adresse e-mail est connue dans notre système et vous avez accès à notre portail client, vous devriez recevoir un e-mail avec de plus amples instructions."
  },
  "If your e-mail address is not known of our representatives will contact you by phone.": {
    "nl": "Als je e-mailadres niet bekend is in ons systeem zal een van onze medewerkers contact met je opnemen van de telefoon.",
    "fr": "Si votre adresse e-mail n'est pas connue de nos représentants, ils vous contacteront par téléphone."
  },
  "Activation successful": {
    "nl": "Activatie succesvol",
    "fr": "Activation réussie"
  },
  "Actions": {
    "nl": "Acties",
    "fr": "Actions"
  },
  "Your account already created": {
    "nl": "Je account is al aangemaakt",
    "fr": "Votre compte est déjà créé"
  },
  "Create new password": {
    "nl": "Nieuw wachtwoord aanmaken",
    "fr": "Créer un nouveau mot de passe"
  },
  "Repeat password": {
    "nl": "Herhaal wachtwoord",
    "fr": "Répéter le mot de passe"
  },
  "Password is required!": {
    "nl": "Een wachtwoord is verplicht!",
    "fr": "Un mot de passe est requis !"
  },
  "Passwords does not match": {
    "nl": "Wachtwoorden komen niet overeen",
    "fr": "Les mots de passe ne correspondent pas"
  },
  "Phone valid": {
    "nl": "Telefoon geldig",
    "fr": "Téléphone valide"
  },
  "Created": {
    "nl": "Aanmaakdatum",
    "fr": "Créé"
  },
  "Reset your password": {
    "nl": "Stel je wachtwoord opnieuw in",
    "fr": "Réinitialiser votre mot de passe"
  },
  "transport-letter": {
    "nl": "transportbrief",
    "fr": "lettre de transport"
  },
  "There is no information for the report generation": {
    "nl": "Er is geen informatie voor het genereren van rapporten",
    "fr": "Il n'y a aucune information pour la génération de rapports"
  },
  "required": {
    "nl": "verplicht",
    "fr": "requis"
  },
  "must be at least ${min} characters": {
    "nl": "moet minimaal ${min} tekens bevatten",
    "fr": "doit contenir au moins ${min} caractères"
  },
  "Dashboard": {
    "nl": "Dashboard",
    "fr": "Tableau de bord"
  },
  "Hi": {
    "nl": "Hallo",
    "fr": "Bonjour"
  },
  "Cancel": {
    "nl": "Annuleren",
    "fr": "Annuler"
  },
  "Today": {
    "nl": "Vandaag",
    "fr": "Aujourd'hui"
  },
  "Clear": {
    "nl": "Wissen",
    "fr": "Effacer"
  },
  "Su": {
    "nl": "Zo",
    "fr": "Di"
  },
  "Mo": {
    "nl": "Ma",
    "fr": "Lu"
  },
  "Tu": {
    "nl": "Di",
    "fr": "Ma"
  },
  "We": {
    "nl": "Wo",
    "fr": "Me"
  },
  "Th": {
    "nl": "Do",
    "fr": "Je"
  },
  "Fr": {
    "nl": "Vr",
    "fr": "Ve"
  },
  "Sa": {
    "nl": "Za",
    "fr": "Sa"
  },
  "Jan": {
    "nl": "Jan",
    "fr": "Jan"
  },
  "Feb": {
    "nl": "Feb",
    "fr": "Fév"
  },
  "Mar": {
    "nl": "Mrt",
    "fr": "Mar"
  },
  "Apr": {
    "nl": "Apr",
    "fr": "Avr"
  },
  "Jun": {
    "nl": "Jun",
    "fr": "Juin"
  },
  "Jul": {
    "nl": "Jul",
    "fr": "Juil"
  },
  "Aug": {
    "nl": "Aug",
    "fr": "Août"
  },
  "Sep": {
    "nl": "Sep",
    "fr": "Sep"
  },
  "Oct": {
    "nl": "Okt",
    "fr": "Oct"
  },
  "Nov": {
    "nl": "Nov",
    "fr": "Nov"
  },
  "Please contact you account manager": {
    "nl": "Neem contact op met je accountmanager",
    "fr": "Veuillez contacter votre gestionnaire de compte"
  },
  "Error": {
    "nl": "Fout",
    "fr": "Erreur"
  },
  "Received": {
    "nl": "Ontvangen",
    "fr": "Reçu"
  },
  "If your e-mail address is known in our system, you will receive an e-mail with a link and instructions on how to login. <br/><br/>If you do not receive an e-mail, you probably need to": {
    "nl": "Als uw e-mail adres bekend is binnen ons systeem, zal u een e-mail ontvangen met een link en instructies om in te loggen. <br/><br/>Als u geen e-mail ontvangt, moet u zichzelf waarschijnlijk nog",
    "fr": "Si votre adresse e-mail est connue dans notre système, vous recevrez un e-mail avec un lien et des instructions sur la façon de vous connecter. <br/><br/>Si vous ne recevez pas d'e-mail, vous devez probablement"
  },
  "register": {
    'nl': 'aanmelden',
    'fr': 'inscrire'
  },
  "Login": {
    'nl': 'Inloggen',
    'fr': 'Connexion'
  },
  "Unfortunately, the link has expired.": {
    'nl': 'Helaas is de link die je hebt gebruikt verlopen.',
    'fr': 'Malheureusement, le lien que vous avez utilisé a expiré.'
  },
  "Please retrieve a new link by pressing the sign in button once more.": {
    'nl': 'Haal een nieuwe link op door nogmaals op de inlogknop te drukken.',
    'fr': 'Veuillez récupérer un nouveau lien en appuyant à nouveau sur le bouton de connexion.'
  }
}
